// src/services/feedbackService.js
import { functions, httpsCallable } from './firebase';

export const submitFeedback = async (userName, userEmail, feedback) => {
    const submitFeedbackFunc = httpsCallable(functions, 'submitFeedback');
    try {
        const result = await submitFeedbackFunc({ userName, userEmail, feedback });
        return result.data;
    } catch (error) {
        console.error('Error submitting feedback:', error);
        throw error;
    }
};