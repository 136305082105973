// src/components/assessment/Questions.js
import React from 'react';
import { useGlobalState } from '../../contexts/GlobalStateContext';
import styles from '../../styles/styles.module.css'; // Import CSS module

<div className={styles.someClass}>Content</div>

const Questions = ({ questions }) => {
    const { state, updateState } = useGlobalState();

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateState({
            userResponses: {
                ...state.userResponses,
                [name]: value
            }
        });
    };

    return (
        <div id="question-container">
            {questions.map((question, index) => (
                <div className="question" key={question.id}>
                    <h3>{index + 1}. {question.text}</h3>
                    <div className="options">
                        {question.options.map(option => (
                            <div className="radio-wrapper" key={option.key}>
                                <input
                                    type="radio"
                                    id={`${question.id}_${option.key}`}
                                    name={question.id}
                                    value={option.key}
                                    onChange={handleChange}
                                    checked={state.userResponses[question.id] === option.key}
                                    required
                                />
                                <label htmlFor={`${question.id}_${option.key}`}>{option.text}</label>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Questions;