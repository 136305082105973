// src/components/ErrorDisplay.js
import React from 'react';
import styles from '../../styles/styles.module.css';

<div className={styles.someClass}>...</div>

const ErrorDisplay = ({ error, setError }) => {
    if (!error) return null;

    const handleClose = () => {
        setError(null);
    };

    return (
        <div id="error-container" className="error-container">
            <p>{error}</p>
            <button onClick={handleClose} className="close-button">X</button>
        </div>
    );
};

export default ErrorDisplay;