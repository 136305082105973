// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Hero from './components/layout/Hero';
import IntroSection from './components/layout/IntroSection';
import CallToAction from './components/layout/CallToAction';
import Footer from './components/layout/Footer';
import TestSection from './components/assessment/TestSection';
import Results from './components/assessment/Results';
import LoadingContainer from './components/layout/LoadingContainer';
import ErrorDisplay from './components/error/ErrorDisplay';
import FeedbackForm from './components/feedback/FeedbackForm';
import NotFound from './components/notfound/NotFound'; // or ./components/notfound/NotFound'
import './App.css'; // If you have component-specific styles

function App() {
    const [error, setError] = useState(null);

    useEffect(() => {
        // Simulate loading
        document.body.classList.add('loaded');
    }, []);

    return (
        <Router>
            <div className="App">
                <ErrorDisplay error={error} setError={setError} />
                <Routes>
                    <Route path="/" element={<Hero />} />
                    <Route path="/intro-section" element={<IntroSection />} />
                    <Route path="/call-to-action" element={<CallToAction />} />
                    <Route path="/test-section" element={<TestSection />} />
                    <Route path="/loading" element={<LoadingContainer />} />
                    <Route path="/results" element={<Results />} />
                    <Route path="*" element={<NotFound />} /> {/* Catch-all route */}
                </Routes>
                <FeedbackForm />
                <Footer />
            </div>
        </Router>
    );
}

export default App;