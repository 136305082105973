// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot
import App from './App';
import { FirebaseProvider } from './contexts/FirebaseContext';
import { GlobalStateProvider } from './contexts/GlobalStateContext';
import ErrorBoundary from './components/error/ErrorBoundary';
import './index.css'; // Import global styles

// Select the root DOM element
const container = document.getElementById('root');

// Create a root.
const root = createRoot(container);

// Initial render
root.render(
    <React.StrictMode>
        <FirebaseProvider>
            <GlobalStateProvider>
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            </GlobalStateProvider>
        </FirebaseProvider>
    </React.StrictMode>
);