// src/components/LoadingContainer.js
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { displayResults } from '../../services/results';
import { useGlobalState } from '../../contexts/GlobalStateContext';
import { gsap } from 'gsap';
import CustomEase from 'gsap/CustomEase';
import styles from '../../styles/LoadingContainer.module.css'; // Import dedicated CSS module

gsap.registerPlugin(CustomEase);

// Define custom easings
CustomEase.create("circle1Ease", "M0,0 C0.25,0.1 0.25,1 1,1");
CustomEase.create("circle2Ease", "M0,0 C0.42,0 0.58,1 1,1");
CustomEase.create("circle3Ease", "M0,0 C0.55,0 0.45,1 1,1");
CustomEase.create("circle4Ease", "M0,0 C0.7,0 0.3,1 1,1");
CustomEase.create("circle5Ease", "M0,0 C0.85,0 0.15,1 1,1");

const LoadingContainer = () => {
    const { state, updateState, setError } = useGlobalState();
    const navigate = useNavigate();

    // Refs for animated elements
    const spinnerContainerRef = useRef(null);
    const loadingTextRef = useRef(null);

    useEffect(() => {
        const fetchAndDisplayResults = async () => {
            try {
                await displayResults(state.normalizedScores, state.userArchetype, setError, updateState);
                navigate('/results');
            } catch (error) {
                console.error('Error fetching results:', error);
                setError('An error occurred while processing your results. Please try again.');
                navigate('/');
            }
        };

        fetchAndDisplayResults();
    }, [state.normalizedScores, state.userArchetype, setError, updateState, navigate]);

    useEffect(() => {
        const spinnerContainer = spinnerContainerRef.current;
        const loadingText = loadingTextRef.current;
      
        // Check for reduced motion preference
        if (window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
          gsap.set(spinnerContainer, { rotation: 0 });
          gsap.set(loadingText, { opacity: 1, y: 0 });
          return;
        }
      
        // GSAP Animations for Spinner Circles
        if (spinnerContainer) {
          const circles = spinnerContainer.querySelectorAll(`.${styles.circle}`);
      
          gsap.to(circles[0], {
            rotation: 360,
            duration: 8,
            ease: "circle1Ease",
            repeat: -1,
            transformOrigin: "50% 50%",
          });
      
          gsap.to(circles[1], {
            rotation: 360,
            duration: 8,
            ease: "circle2Ease",
            repeat: -1,
            transformOrigin: "50% 50%",
          });
      
          gsap.to(circles[2], {
            rotation: 360,
            duration: 8,
            ease: "circle3Ease",
            repeat: -1,
            transformOrigin: "50% 50%",
          });
      
          gsap.to(circles[3], {
            rotation: 360,
            duration: 8,
            ease: "circle4Ease",
            repeat: -1,
            transformOrigin: "50% 50%",
          });
      
          gsap.to(circles[4], {
            rotation: 360,
            duration: 8,
            ease: "circle5Ease",
            repeat: -1,
            transformOrigin: "50% 50%",
          });
        }
      
        // GSAP Animation for Loading Text
        if (loadingText) {
          gsap.fromTo(
            loadingText,
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, duration: 1, delay: 0.5 }
          );
        }
      
        // Cleanup function
        return () => {
          if (spinnerContainer) {
            const circles = spinnerContainer.querySelectorAll(`.${styles.circle}`);
            gsap.killTweensOf(circles);
          }
          gsap.killTweensOf(loadingText);
        };
      }, []); // or include dependencies if needed

    return (
        <section
            id="loading-container"
            className={`${styles.fullScreen} ${styles.loading}`}
            role="status"
            aria-live="polite"
        >
            <div className={styles.content}>
                <div
                    id="spinner-container"
                    className={styles.spinnerContainer}
                    aria-hidden="true"
                    ref={spinnerContainerRef}
                >
                    {/* Orbiting Circles */}
                    <div className={`${styles.circle} ${styles.circle1}`}></div>
                    <div className={`${styles.circle} ${styles.circle2}`}></div>
                    <div className={`${styles.circle} ${styles.circle3}`}></div>
                    <div className={`${styles.circle} ${styles.circle4}`}></div>
                    <div className={`${styles.circle} ${styles.circle5}`}></div>
                </div>
                <p id="loading-text" className={styles.loadingText} ref={loadingTextRef}>
                    Processing your results...
                </p>
                <span className={styles.visuallyHidden}>Loading...</span>
            </div>
        </section>
    );
};

export default LoadingContainer;