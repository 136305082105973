// src/utils/animations.js
import confetti from 'canvas-confetti';
import { gsap } from 'gsap';

export const showConfetti = () => {
    confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
    });
};

export const createStars = () => {
    const starsContainer = document.querySelector('.stars');
    for (let i = 0; i < 100; i++) {
        const star = document.createElement('div');
        star.classList.add('star');
        star.style.top = `${Math.random() * 100}%`;
        star.style.left = `${Math.random() * 100}%`;
        star.style.width = `${Math.random() * 2 + 1}px`;
        star.style.height = `${Math.random() * 2 + 1}px`;
        starsContainer.appendChild(star);
    }
};

export const twinkleStars = () => {
    gsap.to('.star', {
        opacity: Math.random(),
        duration: Math.random() * 2 + 1,
        repeat: -1,
        yoyo: true,
        ease: 'power1.inOut',
        stagger: {
            amount: 5
        }
    });
};

export const animatePhrases = () => {
    gsap.from('.intro-section', {
        opacity: 0,
        y: -50,
        duration: 1,
        ease: 'bounce.out'
    });
};

export const transitionToIntroSection = () => {
    gsap.to('#intro-animation', {
        opacity: 1,
        duration: 1,
        onComplete: () => {
            // Additional transition logic if needed
        }
    });
};