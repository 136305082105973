// src/components/utils/ui.js
import { gsap } from 'gsap';
import { handleError } from './errorHandler';
import { useAppGlobalState } from '../hooks/useGlobalState';

console.log(handleError, useAppGlobalState);

// Function to render questions - to be implemented as React components
export const renderQuestions = (sectionQuestions, state) => {
    // In React, rendering is handled via JSX in components
    // You can pass sectionQuestions as props to a Questions component
};

export const memoize = (fn) => {
    const cache = new Map();
    return (...args) => {
      const key = JSON.stringify(args);
      if (cache.has(key)) return cache.get(key);
      const result = fn(...args);
      cache.set(key, result);
      return result;
    };
  };
  
  export function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  export const updateProgress = (currentQuestion, totalQuestions) => {
    const progressBar = document.getElementById('progress-bar');
    const currentQuestionSpan = document.getElementById('current-question');
    const totalQuestionsSpan = document.getElementById('total-questions');

    const progress = (currentQuestion / totalQuestions) * 100;

    gsap.to(progressBar, {
        width: `${progress}%`,
        duration: 0.5,
        ease: "power1.inOut"
    });

    currentQuestionSpan.textContent = currentQuestion;
    totalQuestionsSpan.textContent = totalQuestions;
};
