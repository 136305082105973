// src/services/scoring.js
import { memoize } from '../utils/ui';
import { likertTraitMappings, binaryTraitMappings, sjMappings, TRAITS } from '../data/traitMapping';
import { archetypeProfiles } from '../data/archetypeProfiles';
import { handleError } from '../utils/errorHandler';

// Calculate the maximum possible scores for each trait
const maxScores = Object.values(TRAITS).reduce((acc, trait) => {
  acc[trait] = 0;
  return acc;
}, {});

// Calculate max scores for likert questions
Object.values(likertTraitMappings).forEach(trait => {
  maxScores[trait] += 5;
});

// Calculate max scores for binary questions
Object.values(binaryTraitMappings).forEach(trait => {
  maxScores[trait] += 1;
});

// Calculate max scores for situational (sj) questions
Object.values(sjMappings).forEach(question => {
  Object.values(question).forEach(option => {
    Object.entries(option).forEach(([trait, value]) => {
      if (maxScores[trait] !== undefined) {
        maxScores[trait] += value;
      } else {
        maxScores[trait] = value;
      }
    });
  });
});

console.log("Maximum possible scores for each trait:", maxScores);

// Function to calculate raw scores
export const calculateRawScores = memoize((responses) => {
  try {
    const scores = Object.values(TRAITS).reduce((acc, trait) => {
      acc[trait] = 0;
      return acc;
    }, {});

    Object.entries(responses).forEach(([questionId, answerKey]) => {
      if (questionId.endsWith('_lk')) {
        const trait = likertTraitMappings[questionId];
        scores[trait] += parseInt(answerKey, 10);
      } else if (questionId.endsWith('_bi')) {
        const trait = binaryTraitMappings[questionId];
        scores[trait] += parseInt(answerKey, 10);
      } else if (questionId.endsWith('_sj')) {
        const traits = sjMappings[questionId][answerKey];
        if (traits) {
          Object.entries(traits).forEach(([trait, value]) => {
            scores[trait] += value;
          });
        }
      }
    });

    return scores;
  } catch (error) {
    handleError(error, 'An error occurred while calculating raw scores.');
    return {};
  }
});

// Function to normalize scores
export const normalizeScores = memoize((rawScores) => {
  try {
    const normalizedScores = {};
    Object.keys(rawScores).forEach(trait => {
      // Avoid division by zero if maxScores[trait] is 0
      normalizedScores[trait] = maxScores[trait] > 0
        ? (rawScores[trait] / maxScores[trait]) * 100
        : 0; // Or handle this case differently based on your logic
    });
    return normalizedScores;
  } catch (error) {
    handleError(error, 'An error occurred while normalizing your scores.');
    return {};
  }
});

// Function to determine archetype
export const determineArchetype = (normalizedScores) => {
  try {
    const archetypeScores = Object.keys(archetypeProfiles).map(archetype => ({
      name: archetype,
      score: calculateWeightedArchetypeScore(archetype, normalizedScores)
    }));

    archetypeScores.sort((a, b) => b.score - a.score);

    // Use a consistent threshold for tie-breaking
    const threshold = 0.05;
    const topScore = archetypeScores[0].score;
    const topScores = archetypeScores.filter(archetype =>
      topScore - archetype.score <= threshold
    );

    // Return the first archetype in the tie if there is one
    return topScores.length > 1
      ? tieBreaker(topScores.map(a => a.name), normalizedScores)
      : archetypeScores[0].name;
  } catch (error) {
    handleError(error, 'An error occurred while determining your archetype.');
    return '';
  }
};

// Function to calculate weighted archetype score
const calculateWeightedArchetypeScore = memoize((archetype, traitScores) => {
  try {
    return Object.entries(traitScores).reduce((score, [trait, value]) => {
      const weight = archetypeProfiles[archetype]?.[trait] || 0;
      return score + (value * weight);
    }, 0);
  } catch (error) {
    handleError(error, 'An error occurred while calculating archetype scores.');
    return 0;
  }
});

// Tie-breaker function
const tieBreaker = (tiedArchetypes, traitScores) => {
  try {
    const topTrait = Object.entries(traitScores)
      .sort((a, b) => b[1] - a[1])[0][0];

    return tiedArchetypes.reduce((highest, archetype) => {
      const matchScore = archetypeProfiles[archetype][topTrait] || 0;
      return (matchScore > highest.matchScore)
        ? { archetype, matchScore }
        : highest;
    }, { archetype: null, matchScore: -Infinity }).archetype;

  } catch (error) {
    handleError(error, 'An error occurred during tie-breaking.');
    return tiedArchetypes[0] || '';
  }
};

// Function to update scores in the state
export const updateScoresInState = (responses, updateState, setError) => {
    const rawScores = calculateRawScores(responses); 
    const normalizedScores = normalizeScores(rawScores); 
    const archetype = determineArchetype(normalizedScores); 

    updateState({
      rawScores,
      normalizedScores,
      userArchetype: archetype
    });
};