// src/components/CallToAction.js
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';
import styles from '../../styles/CallToAction.module.css'; // Import dedicated CSS module
import { useStartAssessment } from '../../hooks/useStartAssessment';

gsap.registerPlugin(CustomEase);

// Define custom easing if needed
// CustomEase.create("customEase", "cubic-bezier(0.25, 0.1, 0.25, 1)");

const CallToAction = () => {
    const startAssessment = useStartAssessment();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const navigate = useNavigate();

    const sectionRef = useRef(null);

    useEffect(() => {
        // Animate the entire section on mount
        gsap.from(sectionRef.current, {
            opacity: 0,
            y: 50,
            duration: 1,
            delay: 0.5,
            ease: "power2.out"
        });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        // You can perform additional validation or processing here
        startAssessment({ name, email });
        // Optionally navigate after starting the assessment
        navigate('/test-section');
    };

    return (
        <section
            id="call-to-action"
            className={styles.fullScreen}
            ref={sectionRef}
            aria-labelledby="call-to-action-heading"
        >
            <h2 id="call-to-action-heading">Take Control. Accelerate Your Future.</h2>
            <form id="user-form" className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.formGroup}>
                    <label htmlFor="name" className={styles.label}>Your Name</label>
                    <input
                        id="name"
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        className={styles.input}
                    />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="email" className={styles.label}>Your Email</label>
                    <input
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className={styles.input}
                    />
                </div>
                <button
                    id="start-button"
                    className={styles.btn}
                    type="submit"
                >
                    Begin Your Assessment
                </button>
            </form>
        </section>
    );
};

export default CallToAction;