// src/data/traitMapping.js
export const TRAITS = {
    ADAPTABILITY: 'Adaptability',
    RESILIENCE: 'Resilience',
    OPENNESS_TO_EXPERIENCES: 'OpennessToExperiences',
    CONSCIENTIOUSNESS: 'Conscientiousness',
    EMOTIONAL_MATURITY: 'EmotionalMaturity',
    INNOVATIVENESS: 'Innovativeness',
    PROACTIVITY: 'Proactivity',
    COLLABORATION: 'Collaboration',
    CRITICAL_THINKING: 'CriticalThinking',
    DIGITAL_LITERACY: 'DigitalLiteracy'
};

export const likertTraitMappings = {
    q1_lk: TRAITS.ADAPTABILITY,
    q2_lk: TRAITS.RESILIENCE,
    q3_lk: TRAITS.OPENNESS_TO_EXPERIENCES,
    q4_lk: TRAITS.CONSCIENTIOUSNESS,
    q5_lk: TRAITS.EMOTIONAL_MATURITY,
    q6_lk: TRAITS.INNOVATIVENESS,
    q7_lk: TRAITS.PROACTIVITY,
    q8_lk: TRAITS.COLLABORATION,
    q9_lk: TRAITS.CRITICAL_THINKING,
    q10_lk: TRAITS.DIGITAL_LITERACY,
    q11_lk: TRAITS.ADAPTABILITY,
    q12_lk: TRAITS.RESILIENCE,
    q13_lk: TRAITS.OPENNESS_TO_EXPERIENCES,
    q14_lk: TRAITS.CONSCIENTIOUSNESS,
    q15_lk: TRAITS.EMOTIONAL_MATURITY,
    q16_lk: TRAITS.INNOVATIVENESS,
    q17_lk: TRAITS.PROACTIVITY,
    q18_lk: TRAITS.COLLABORATION,
    q19_lk: TRAITS.CRITICAL_THINKING,
    q20_lk: TRAITS.DIGITAL_LITERACY,
};

export const binaryTraitMappings = {
    q1_bi: TRAITS.ADAPTABILITY,
    q2_bi: TRAITS.RESILIENCE,
    q3_bi: TRAITS.OPENNESS_TO_EXPERIENCES,
    q4_bi: TRAITS.CONSCIENTIOUSNESS,
    q5_bi: TRAITS.EMOTIONAL_MATURITY,
    q6_bi: TRAITS.INNOVATIVENESS,
    q7_bi: TRAITS.PROACTIVITY,
    q8_bi: TRAITS.COLLABORATION,
    q9_bi: TRAITS.CRITICAL_THINKING,
    q10_bi: TRAITS.DIGITAL_LITERACY,
    q11_bi: TRAITS.ADAPTABILITY,
    q12_bi: TRAITS.RESILIENCE,
    q13_bi: TRAITS.OPENNESS_TO_EXPERIENCES,
    q14_bi: TRAITS.CONSCIENTIOUSNESS,
    q15_bi: TRAITS.EMOTIONAL_MATURITY,
    q16_bi: TRAITS.INNOVATIVENESS,
    q17_bi: TRAITS.PROACTIVITY,
    q18_bi: TRAITS.COLLABORATION,
    q19_bi: TRAITS.CRITICAL_THINKING,
    q20_bi: TRAITS.DIGITAL_LITERACY
};

export const sjMappings = {
    q1_sj: {
        a: { [TRAITS.CONSCIENTIOUSNESS]: 1, [TRAITS.PROACTIVITY]: 1 },
        b: { [TRAITS.COLLABORATION]: 1, [TRAITS.ADAPTABILITY]: 1 },
        c: { [TRAITS.RESILIENCE]: 1, [TRAITS.EMOTIONAL_MATURITY]: 1 }
    },
    q2_sj: {
        a: { [TRAITS.OPENNESS_TO_EXPERIENCES]: 1, [TRAITS.INNOVATIVENESS]: 1 },
        b: { [TRAITS.CRITICAL_THINKING]: 1, [TRAITS.CONSCIENTIOUSNESS]: 1 },
        c: { [TRAITS.COLLABORATION]: 1, [TRAITS.EMOTIONAL_MATURITY]: 1 }
    },
    q3_sj: {
        a: { [TRAITS.DIGITAL_LITERACY]: 1, [TRAITS.PROACTIVITY]: 1 },
        b: { [TRAITS.COLLABORATION]: 1, [TRAITS.ADAPTABILITY]: 1 },
        c: { [TRAITS.RESILIENCE]: 1, [TRAITS.CONSCIENTIOUSNESS]: 1 },
    },
    q4_sj: {
        a: { [TRAITS.EMOTIONAL_MATURITY]: 1, [TRAITS.CRITICAL_THINKING]: 1 },
        b: { [TRAITS.RESILIENCE]: 1, [TRAITS.ADAPTABILITY]: 1 },
        c: { [TRAITS.OPENNESS_TO_EXPERIENCES]: 1, [TRAITS.COLLABORATION]: 1 },
    },
    q5_sj: {
        a: { [TRAITS.INNOVATIVENESS]: 1, [TRAITS.DIGITAL_LITERACY]: 1 },
        b: { [TRAITS.ADAPTABILITY]: 1, [TRAITS.CONSCIENTIOUSNESS]: 1 },
        c: { [TRAITS.PROACTIVITY]: 1, [TRAITS.COLLABORATION]: 1 },
    },
    q6_sj: {
        a: { [TRAITS.CONSCIENTIOUSNESS]: 1, [TRAITS.PROACTIVITY]: 1 },
        b: { [TRAITS.COLLABORATION]: 1, [TRAITS.ADAPTABILITY]: 1 },
        c: { [TRAITS.RESILIENCE]: 1, [TRAITS.EMOTIONAL_MATURITY]: 1 },
    },
    q7_sj: {
        a: { [TRAITS.DIGITAL_LITERACY]: 1, [TRAITS.OPENNESS_TO_EXPERIENCES]: 1 },
        b: { [TRAITS.RESILIENCE]: 1, [TRAITS.EMOTIONAL_MATURITY]: 1 },
        c: { [TRAITS.CRITICAL_THINKING]: 1, [TRAITS.INNOVATIVENESS]: 1 },
    },
    q8_sj: {
        a: { [TRAITS.CRITICAL_THINKING]: 1, [TRAITS.DIGITAL_LITERACY]: 1 },
        b: { [TRAITS.OPENNESS_TO_EXPERIENCES]: 1, [TRAITS.EMOTIONAL_MATURITY]: 1 },
        c: { [TRAITS.ADAPTABILITY]: 1, [TRAITS.INNOVATIVENESS]: 1 },
    },
    q9_sj: {
        a: { [TRAITS.PROACTIVITY]: 1, [TRAITS.ADAPTABILITY]: 1 },
        b: { [TRAITS.CRITICAL_THINKING]: 1, [TRAITS.OPENNESS_TO_EXPERIENCES]: 1 },
        c: { [TRAITS.RESILIENCE]: 1, [TRAITS.INNOVATIVENESS]: 1 },
    },
    q10_sj: {
        a: { [TRAITS.PROACTIVITY]: 1, [TRAITS.INNOVATIVENESS]: 1 },
        b: { [TRAITS.DIGITAL_LITERACY]: 1, [TRAITS.CONSCIENTIOUSNESS]: 1 },
        c: { [TRAITS.CRITICAL_THINKING]: 1, [TRAITS.EMOTIONAL_MATURITY]: 1 },
    },
    q11_sj: {
        a: { [TRAITS.DIGITAL_LITERACY]: 1, [TRAITS.ADAPTABILITY]: 1 },
        b: { [TRAITS.EMOTIONAL_MATURITY]: 1, [TRAITS.OPENNESS_TO_EXPERIENCES]: 1 },
        c: { [TRAITS.CRITICAL_THINKING]: 1, [TRAITS.INNOVATIVENESS]: 1 },
    },
    q12_sj: {
        a: { [TRAITS.CRITICAL_THINKING]: 1, [TRAITS.CONSCIENTIOUSNESS]: 1 },
        b: { [TRAITS.OPENNESS_TO_EXPERIENCES]: 2 },
        c: { [TRAITS.PROACTIVITY]: 1, [TRAITS.RESILIENCE]: 1 },
    },
    q13_sj: {
        a: { [TRAITS.COLLABORATION]: 1, [TRAITS.DIGITAL_LITERACY]: 1 },
        b: { [TRAITS.OPENNESS_TO_EXPERIENCES]: 2 },
        c: { [TRAITS.CONSCIENTIOUSNESS]: 1, [TRAITS.PROACTIVITY]: 1 },
    },
    q14_sj: {
        a: { [TRAITS.COLLABORATION]: 1, [TRAITS.ADAPTABILITY]: 1 },
        b: { [TRAITS.EMOTIONAL_MATURITY]: 1, [TRAITS.RESILIENCE]: 1 },
        c: { [TRAITS.PROACTIVITY]: 1, [TRAITS.INNOVATIVENESS]: 1 },
    },
    q15_sj: {
        a: { [TRAITS.COLLABORATION]: 1, [TRAITS.INNOVATIVENESS]: 1 },
        b: { [TRAITS.DIGITAL_LITERACY]: 1, [TRAITS.CONSCIENTIOUSNESS]: 1 },
        c: { [TRAITS.ADAPTABILITY]: 1, [TRAITS.CRITICAL_THINKING]: 1 },
    },
    q16_sj: {
        a: { [TRAITS.EMOTIONAL_MATURITY]: 1, [TRAITS.COLLABORATION]: 1 },
        b: { [TRAITS.PROACTIVITY]: 1, [TRAITS.RESILIENCE]: 1 },
        c: { [TRAITS.OPENNESS_TO_EXPERIENCES]: 1, [TRAITS.CRITICAL_THINKING]: 1 },
    },
    q17_sj: {
        a: { [TRAITS.DIGITAL_LITERACY]: 1, [TRAITS.CONSCIENTIOUSNESS]: 1 },
        b: { [TRAITS.RESILIENCE]: 1, [TRAITS.ADAPTABILITY]: 1 },
        c: { [TRAITS.PROACTIVITY]: 1, [TRAITS.INNOVATIVENESS]: 1 },
    },
    q18_sj: {
        a: { [TRAITS.COLLABORATION]: 1, [TRAITS.ADAPTABILITY]: 1 },
        b: { [TRAITS.CONSCIENTIOUSNESS]: 1, [TRAITS.CRITICAL_THINKING]: 1 },
        c: { [TRAITS.PROACTIVITY]: 1, [TRAITS.EMOTIONAL_MATURITY]: 1 },
    },
    q19_sj: {
        a: { [TRAITS.EMOTIONAL_MATURITY]: 1, [TRAITS.CONSCIENTIOUSNESS]: 1 },
        b: { [TRAITS.RESILIENCE]: 1, [TRAITS.DIGITAL_LITERACY]: 1 },
        c: { [TRAITS.CRITICAL_THINKING]: 1, [TRAITS.INNOVATIVENESS]: 1 },
    },
    q20_sj: {
        a: { [TRAITS.EMOTIONAL_MATURITY]: 1, [TRAITS.DIGITAL_LITERACY]: 1 },
        b: { [TRAITS.RESILIENCE]: 1, [TRAITS.OPENNESS_TO_EXPERIENCES]: 1 },
        c: { [TRAITS.CRITICAL_THINKING]: 1, [TRAITS.ADAPTABILITY]: 1 },
    },
  };