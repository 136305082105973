// src/components/FeedbackForm.js
import React, { useState } from 'react';
import { submitFeedback } from '../../services/feedbackService';
import { useGlobalState } from '../../contexts/GlobalStateContext';
import styles from '../../styles/styles.module.css';

<div className={styles.someClass}>...</div>

const FeedbackForm = () => {
    const { state, setError } = useGlobalState();
    const [feedback, setFeedback] = useState('');
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!feedback.trim()) {
            setError('Feedback cannot be empty.');
            return;
        }

        try {
            await submitFeedback(state.userName, state.userEmail, feedback);
            setSuccess(true);
            setFeedback('');
        } catch (error) {
            setError('Failed to submit feedback. Please try again later.');
        }
    };

    return (
        <div id="feedback-form" className="feedback-form">
            <h3>We Value Your Feedback</h3>
            {success ? (
                <p>Thank you for your feedback!</p>
            ) : (
                <form onSubmit={handleSubmit}>
                    <textarea
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        placeholder="Enter your feedback here..."
                        required
                    />
                    <button type="submit" className="btn">Submit Feedback</button>
                </form>
            )}
        </div>
    );
};

export default FeedbackForm;