// src/components/charts/PieChart.js
import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import styles from '../../styles/styles.module.css'; // Import CSS module

Chart.register(ArcElement, Tooltip, Legend);

<div className={styles.someClass}>...</div>

const PieChartComponent = ({ data }) => {
    const chartData = {
        labels: Object.keys(data),
        datasets: [
            {
                label: 'Trait Distribution',
                data: Object.values(data),
                backgroundColor: [
                    '#3498db',
                    '#2ecc71',
                    '#e74c3c',
                    '#9b59b6',
                    '#f1c40f',
                    '#e67e22',
                    '#1abc9c',
                    '#34495e',
                    '#7f8c8d',
                    '#16a085'
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Trait Distribution',
            },
        },
    };

    return <Pie data={chartData} options={options} />;
};

export default PieChartComponent;