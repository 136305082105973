// src/data/testQuestions/situationalQuestions.js
import { TRAITS } from '../traitMapping';

export const situationalQuestions = [
    {
        id: "q1_sj",
        text: "You work at a marketing company that specializes in creating presentations for large national accounts. Your manager asks you to prepare a comprehensive presentation for a high-profile client within an extremely tight 48-hour deadline. How do you respond?",
        options: [
            { key: "a", text: "Rearrange your schedule, canceling less urgent meetings, to allocate the time needed to complete the presentation on time." },
            { key: "b", text: "Inform your manager that the timeframe is too tight to ensure quality and request an extension." },
            { key: "c", text: "Ask a colleague with relevant expertise to assist, ensuring the presentation is completed by the deadline." }
        ],
        traits: {
            a: { [TRAITS.CONSCIENTIOUSNESS]: 1, [TRAITS.PROACTIVITY]: 1 },
            b: { [TRAITS.RESILIENCE]: 1, [TRAITS.EMOTIONAL_MATURITY]: 1 },
            c: { [TRAITS.COLLABORATION]: 1, [TRAITS.ADAPTABILITY]: 1 }
        }
    },
    {
        id: "q2_sj",
        text: "You are working on a software development project for a healthcare application that must comply with strict regulatory standards. Your colleague suggests a new method for solving a persistent coding issue that could improve compliance and efficiency. How do you respond?",
        options: [
            { key: "a", text: "Try the new method in a test environment immediately to see if it resolves the issue." },
            { key: "b", text: "Evaluate the new method by comparing it with your current approach and assess its potential impact on compliance." },
            { key: "c", text: "Discuss the suggested method with the team during your next meeting to get their input before making any changes." }
        ],
        traits: {
            a: { [TRAITS.OPENNESS_TO_EXPERIENCES]: 1, [TRAITS.INNOVATIVENESS]: 1 },
            b: { [TRAITS.CRITICAL_THINKING]: 1, [TRAITS.CONSCIENTIOUSNESS]: 1 },
            c: { [TRAITS.COLLABORATION]: 1, [TRAITS.EMOTIONAL_MATURITY]: 1 }
        }
    },
    {
        id: "q3_sj",
        text: "While working remotely from a co-working space in Bangkok, you encounter a technical issue with your laptop that prevents you from accessing important files and joining video calls. How do you respond?",
        options: [
            { key: "a", text: "Troubleshoot the issue to get back online as quickly as possible." },
            { key: "b", text: "Contact a colleague who might have experienced a similar issue to help you resolve it." },
            { key: "c", text: "Report the issue to the IT department and follow their instructions while waiting for assistance." }
        ],
        traits: {
            a: { [TRAITS.DIGITAL_LITERACY]: 1, [TRAITS.PROACTIVITY]: 1 },
            b: { [TRAITS.COLLABORATION]: 1, [TRAITS.ADAPTABILITY]: 1 },
            c: { [TRAITS.RESILIENCE]: 1, [TRAITS.CONSCIENTIOUSNESS]: 1 }
        }
    },
    {
        id: "q4_sj",
        text: "During a family reunion at your parents' house, your sibling approaches you to discuss a sensitive topic about family finances that has been causing stress. How do you respond?",
        options: [
            { key: "a", text: "Find a quiet place to listen carefully to their concerns and provide thoughtful feedback." },
            { key: "b", text: "Offer your support but suggest talking in private at a less busy time to have a detailed conversation." },
            { key: "c", text: "Encourage them to share their feelings and relate with your own experiences to help them feel understood and supported." }
        ],
        traits: {
            a: { [TRAITS.EMOTIONAL_MATURITY]: 1, [TRAITS.CRITICAL_THINKING]: 1 },
            b: { [TRAITS.RESILIENCE]: 1, [TRAITS.ADAPTABILITY]: 1 },
            c: { [TRAITS.OPENNESS_TO_EXPERIENCES]: 1, [TRAITS.COLLABORATION]: 1 }
        }
    },
    {
        id: "q5_sj",
        text: "Your team at your tech startup is struggling with outdated project management software that significantly slows down productivity. How do you respond?",
        options: [
            { key: "a", text: "Research and propose a modern, more efficient project management tool that could enhance productivity." },
            { key: "b", text: "Find ways to optimize your workflow within the current software's limitations while planning for future improvements." },
            { key: "c", text: "Raise the issue with your manager, detailing how the outdated software impacts productivity, and suggest transitioning to a better tool." }
        ],
        traits: {
            a: { [TRAITS.INNOVATIVENESS]: 1, [TRAITS.DIGITAL_LITERACY]: 1 },
            b: { [TRAITS.ADAPTABILITY]: 1, [TRAITS.CONSCIENTIOUSNESS]: 1 },
            c: { [TRAITS.PROACTIVITY]: 1, [TRAITS.COLLABORATION]: 1 }
        }
    },
    {
        id: "q6_sj",
        text: "You are planning a vacation to Hawaii next month, but you have several critical projects with tight deadlines that need to be completed before you leave. How do you respond?",
        options: [
            { key: "a", text: "Rearrange your work schedule and put in extra hours if necessary to complete all critical tasks before your vacation." },
            { key: "b", text: "Assign some of your tasks to trusted colleagues, ensuring they are briefed properly to handle the work in your absence." },
            { key: "c", text: "Discuss your workload with your manager, explaining the situation, and request additional support or an extension on some deadlines." }
        ],
        traits: {
            a: { [TRAITS.CONSCIENTIOUSNESS]: 1, [TRAITS.PROACTIVITY]: 1 },
            b: { [TRAITS.COLLABORATION]: 1, [TRAITS.ADAPTABILITY]: 1 },
            c: { [TRAITS.RESILIENCE]: 1, [TRAITS.EMOTIONAL_MATURITY]: 1 }
        }
    },
    {
        id: "q7_sj",
        text: "You are assigned a new project at your job as a mobile app developer, requiring expertise in a programming language you are not familiar with. How do you respond?",
        options: [
            { key: "a", text: "Enroll in online courses and spend your time learning the necessary programming language and skills." },
            { key: "b", text: "Seek guidance from a mentor or experienced colleague who is proficient in the programming language to help you understand the requirements." },
            { key: "c", text: "Analyze the project requirements, break them into smaller tasks, and develop a learning plan that integrates new skills with project milestones." }
        ],
        traits: {
            a: { [TRAITS.DIGITAL_LITERACY]: 1, [TRAITS.OPENNESS_TO_EXPERIENCES]: 1 },
            b: { [TRAITS.RESILIENCE]: 1, [TRAITS.EMOTIONAL_MATURITY]: 1 },
            c: { [TRAITS.CRITICAL_THINKING]: 1, [TRAITS.INNOVATIVENESS]: 1 }
        }
    },
    {
        id: "q8_sj",
        text: "You are leading a team responsible for launching a new marketing campaign, but you lack some crucial market research data needed for decision-making. How do you respond?",
        options: [
            { key: "a", text: "Gather and analyze all available data, using market trends and previous campaign results to inform your decision." },
            { key: "b", text: "Consult with market research experts and stakeholders to obtain the necessary insights and data before proceeding." },
            { key: "c", text: "Make a provisional decision based on the best available information and adjust the strategy as new data becomes available." }
        ],
        traits: {
            a: { [TRAITS.CRITICAL_THINKING]: 1, [TRAITS.DIGITAL_LITERACY]: 1 },
            b: { [TRAITS.OPENNESS_TO_EXPERIENCES]: 1, [TRAITS.EMOTIONAL_MATURITY]: 1 },
            c: { [TRAITS.ADAPTABILITY]: 1, [TRAITS.INNOVATIVENESS]: 1 }
        }
    },
    {
        id: "q9_sj",
        text: "You and a coworker are at odds about the strategic direction for a project you are jointly leading at your company's annual planning meeting. How do you respond?",
        options: [
            { key: "a", text: "Schedule a dedicated meeting with your coworker to discuss and resolve the differences, aiming for a mutually beneficial solution." },
            { key: "b", text: "Analyze both perspectives thoroughly to determine the most effective solution for the project's success and present your findings." },
            { key: "c", text: "Propose a compromise that incorporates key elements from both perspectives, demonstrating flexibility in finding a solution." }
        ],
        traits: {
            a: { [TRAITS.COLLABORATION]: 1, [TRAITS.EMOTIONAL_MATURITY]: 1 },
            b: { [TRAITS.CRITICAL_THINKING]: 1, [TRAITS.PROACTIVITY]: 1 },
            c: { [TRAITS.ADAPTABILITY]: 1, [TRAITS.OPENNESS_TO_EXPERIENCES]: 1 }
        }
    },
    {
        id: "q10_sj",
        text: "You have been given the responsibility to implement a new customer relationship management (CRM) system in your team to improve client interactions and sales tracking. How do you respond?",
        options: [
            { key: "a", text: "Take a lead role in the CRM implementation, guiding your team through each step of the transition process." },
            { key: "b", text: "Arrange for a comprehensive training session to ensure your team understands how to use the new CRM system effectively." },
            { key: "c", text: "Gradually introduce the CRM system, providing ongoing support and resources as your team adjusts to the new technology." }
        ],
        traits: {
            a: { [TRAITS.PROACTIVITY]: 1, [TRAITS.INNOVATIVENESS]: 1 },
            b: { [TRAITS.DIGITAL_LITERACY]: 1, [TRAITS.CONSCIENTIOUSNESS]: 1 },
            c: { [TRAITS.CRITICAL_THINKING]: 1, [TRAITS.EMOTIONAL_MATURITY]: 1 }
        }
    },
    {
        id: "q11_sj",
        text: "During a family dinner, a heated argument arises between two relatives about politics. How do you respond?",
        options: [
            { key: "a", text: "Suggest a brief pause, allowing everyone to reflect and cool down before continuing the discussion." },
            { key: "b", text: "Recommend taking a break and resuming the discussion later when everyone is calmer to avoid further conflict." },
            { key: "c", text: "Facilitate a structured conversation to address the root of the issue rationally, ensuring each person has a chance to speak and be heard." }
        ],
        traits: {
            a: { [TRAITS.EMOTIONAL_MATURITY]: 1, [TRAITS.COLLABORATION]: 1 },
            b: { [TRAITS.PROACTIVITY]: 1, [TRAITS.RESILIENCE]: 1 },
            c: { [TRAITS.OPENNESS_TO_EXPERIENCES]: 1, [TRAITS.CRITICAL_THINKING]: 1 }
        }
    },
    {
        id: "q12_sj",
        text: "Your friend is going through a tough breakup and reaches out to you for support. They just found out their partner was unfaithful. How do you respond?",
        options: [
            { key: "a", text: "Carefully analyze their situation, offer thoughtful advice, and help them create a structured plan for moving forward." },
            { key: "b", text: "Encourage them to engage in activities they enjoy, like going for a hike or attending a music concert, to distract and uplift their mood." },
            { key: "c", text: "Suggest new ways to cope, such as picking up new hobbies like painting or joining social groups like a local book club, to help them move forward." }
        ],
        traits: {
            a: { [TRAITS.CRITICAL_THINKING]: 1, [TRAITS.CONSCIENTIOUSNESS]: 1 },
            b: { [TRAITS.OPENNESS_TO_EXPERIENCES]: 1, [TRAITS.RESILIENCE]: 1 },
            c: { [TRAITS.PROACTIVITY]: 1, [TRAITS.RESILIENCE]: 1 }
        }
    },
    {
        id: "q13_sj",
        text: "You and your partner disagree on how to spend your shared $10,000 savings. Your partner wants to invest in the stock market, while you prefer to use it for a home renovation. How do you respond?",
        options: [
            { key: "a", text: "Explore various investment options that combine both of your preferences and create a shared plan." },
            { key: "b", text: "Propose a detailed financial plan that considers both perspectives, including potential long-term benefits and compromises." },
            { key: "c", text: "Take proactive steps to address the disagreement by initiating a calm and respectful discussion to find a solution." }
        ],
        traits: {
            a: { [TRAITS.COLLABORATION]: 1, [TRAITS.DIGITAL_LITERACY]: 1 },
            b: { [TRAITS.OPENNESS_TO_EXPERIENCES]: 1, [TRAITS.CRITICAL_THINKING]: 1 },
            c: { [TRAITS.CONSCIENTIOUSNESS]: 1, [TRAITS.PROACTIVITY]: 1 }
        }
    },
    {
        id: "q14_sj",
        text: "Your 10-year-old child is struggling with their math homework and becomes frustrated after failing to solve multiplication problems. How do you respond?",
        options: [
            { key: "a", text: "Use educational software or online resources specifically designed for improving multiplication skills to help them understand the material and make learning more engaging." },
            { key: "b", text: "Encourage them to take breaks and try different approaches, such as using visual aids or playing math games, to make the learning process less stressful." },
            { key: "c", text: "Introduce new methods to make learning more enjoyable, such as gamified learning or hands-on activities involving multiplication with physical objects." }
        ],
        traits: {
            a: { [TRAITS.COLLABORATION]: 1, [TRAITS.ADAPTABILITY]: 1 },
            b: { [TRAITS.EMOTIONAL_MATURITY]: 1, [TRAITS.RESILIENCE]: 1 },
            c: { [TRAITS.PROACTIVITY]: 1, [TRAITS.INNOVATIVENESS]: 1 }
        }
    },
    {
        id: "q15_sj",
        text: "A close friend confides in you about their stressful work environment and the anxiety it brings, afraid that it's taking a toll on their mental health. How do you respond?",
        options: [
            { key: "a", text: "Listen without judgment and offer your support, creating a safe space for them to share their feelings." },
            { key: "b", text: "Help them take practical steps to address their issues, such as finding a therapist or creating a self-care plan that includes regular exercise and relaxation techniques." },
            { key: "c", text: "Encourage them to explore different ways to manage their mental health, such as mindfulness practices, yoga, or creative outlets like journaling or art." }
        ],
        traits: {
            a: { [TRAITS.EMOTIONAL_MATURITY]: 1, [TRAITS.CONSCIENTIOUSNESS]: 1 },
            b: { [TRAITS.RESILIENCE]: 1, [TRAITS.DIGITAL_LITERACY]: 1 },
            c: { [TRAITS.CRITICAL_THINKING]: 1, [TRAITS.INNOVATIVENESS]: 1 }
        }
    },
    {
        id: "q16_sj",
        text: "You are planning a surprise 30th birthday party for your sister, but you're unsure about her preferences for the theme. How do you respond?",
        options: [
            { key: "a", text: "Work with other family members and friends to get ideas and plan the party, ensuring it aligns with her interests." },
            { key: "b", text: "Use online tools such as Pinterest or party planning websites to research and organize the event, making use of digital resources to streamline the planning process." },
            { key: "c", text: "Adjust your plans based on feedback from people who know her well and available information about her recent interests and activities." }
        ],
        traits: {
            a: { [TRAITS.COLLABORATION]: 1, [TRAITS.INNOVATIVENESS]: 1 },
            b: { [TRAITS.DIGITAL_LITERACY]: 1, [TRAITS.CONSCIENTIOUSNESS]: 1 },
            c: { [TRAITS.ADAPTABILITY]: 1, [TRAITS.CRITICAL_THINKING]: 1 }
        }
    },
    {
        id: "q17_sj",
        text: "Your neighbor asks for your help with building a garden shed over the weekend, but you had planned to relax and watch a new movie. How do you respond?",
        options: [
            { key: "a", text: "Put your plans on hold to help them finish the shed until the job is done." },
            { key: "b", text: "Explain your need for relaxation and offer to help at a different time, prioritizing your well-being while still offering assistance." },
            { key: "c", text: "Suggest alternative solutions, like providing them with instructional videos or a step-by-step guide, to help them complete the project independently." }
        ],
        traits: {
            a: { [TRAITS.EMOTIONAL_MATURITY]: 1, [TRAITS.CONSCIENTIOUSNESS]: 1 },
            b: { [TRAITS.RESILIENCE]: 1, [TRAITS.DIGITAL_LITERACY]: 1 },
            c: { [TRAITS.CRITICAL_THINKING]: 1, [TRAITS.INNOVATIVENESS]: 1 }
        }
    },
    {
        id: "q18_sj",
        text: "Your family is considering adopting a pet, but opinions on the type of pet vary. Your kids want a dog, but your partner prefers a cat. How do you respond?",
        options: [
            { key: "a", text: "Research different pets online to gather information about the care requirements, temperaments, and costs of both dogs and cats, then present your findings." },
            { key: "b", text: "Visit animal shelters together to explore different options and interact with both dogs and cats, facilitating a hands-on decision-making process." },
            { key: "c", text: "Create a detailed pros and cons list for each type of pet, considering factors like allergies, lifestyle compatibility, and long-term commitment, to facilitate the decision." }
        ],
        traits: {
            a: { [TRAITS.COLLABORATION]: 1, [TRAITS.ADAPTABILITY]: 1 },
            b: { [TRAITS.CONSCIENTIOUSNESS]: 1, [TRAITS.CRITICAL_THINKING]: 1 },
            c: { [TRAITS.PROACTIVITY]: 1, [TRAITS.EMOTIONAL_MATURITY]: 1 }
        }
    },
    {
        id: "q19_sj",
        text: "You've been invited to participate in a local community art project where you need to create a piece that represents your neighborhood. You have limited artistic experience. How do you respond?",
        options: [
            { key: "a", text: "Carefully plan your artwork by observing your neighborhood and sketching ideas, focusing on creating a meaningful representation despite your limited experience." },
            { key: "b", text: "Look up online tutorials and join local art workshops to quickly improve your skills and confidently contribute to the project." },
            { key: "c", text: "Propose an unconventional approach, such as a collaborative piece or using unconventional materials, to overcome your lack of traditional artistic skills." }
        ],
        traits: {
            a: { [TRAITS.EMOTIONAL_MATURITY]: 1, [TRAITS.CONSCIENTIOUSNESS]: 1 },
            b: { [TRAITS.RESILIENCE]: 1, [TRAITS.DIGITAL_LITERACY]: 1 },
            c: { [TRAITS.CRITICAL_THINKING]: 1, [TRAITS.INNOVATIVENESS]: 1 }
        }
    },
    {
        id: "q20_sj",
        text: "You and your partner are deciding on a location for your next vacation. Your partner wants to go to the mountains, while you prefer the beach. How do you respond?",
        options: [
            { key: "a", text: "Research destinations that offer both mountain and beach experiences, and present a thoughtful, balanced proposal to your partner." },
            { key: "b", text: "View this as an opportunity to try something new. Suggest alternating preferences each year, starting with your partner's choice of mountains this time." },
            { key: "c", text: "Analyze the pros and cons of each option, considering factors like weather, activities, and budget, then propose a flexible itinerary that incorporates elements of both preferences." }
        ],
        traits: {
            a: { [TRAITS.EMOTIONAL_MATURITY]: 1, [TRAITS.DIGITAL_LITERACY]: 1 },
            b: { [TRAITS.RESILIENCE]: 1, [TRAITS.OPENNESS_TO_EXPERIENCES]: 1 },
            c: { [TRAITS.CRITICAL_THINKING]: 1, [TRAITS.ADAPTABILITY]: 1 }
        }
    }
];