// src/data/testQuestions/likertQuestions.js
import { TRAITS } from '../traitMapping';

export const likertQuestions = [
    { id: "q1_lk", text: "I quickly adapt my plans when unexpected changes occur.", trait: TRAITS.ADAPTABILITY },
    { id: "q2_lk", text: "I maintain focus and motivation even after facing significant challenges.", trait: TRAITS.RESILIENCE },
    { id: "q3_lk", text: "I actively seek out new ideas and approaches in my professional projects.", trait: TRAITS.OPENNESS_TO_EXPERIENCES },
    { id: "q4_lk", text: "I consistently meet deadlines and maintain high-quality standards with any project or task.", trait: TRAITS.CONSCIENTIOUSNESS },
    { id: "q5_lk", text: "I remain calm and composed in high-pressure situations.", trait: TRAITS.EMOTIONAL_MATURITY },
    { id: "q6_lk", text: "I regularly create unique and effective solutions to problems.", trait: TRAITS.INNOVATIVENESS },
    { id: "q7_lk", text: "I address potential issues at work before they escalate.", trait: TRAITS.PROACTIVITY },
    { id: "q8_lk", text: "I work effectively with others to achieve team goals.", trait: TRAITS.COLLABORATION },
    { id: "q9_lk", text: "I carefully evaluate information before making decisions.", trait: TRAITS.CRITICAL_THINKING },
    { id: "q10_lk", text: "I confidently embrace new tools and technologies.", trait: TRAITS.DIGITAL_LITERACY },
    { id: "q11_lk", text: "I thrive in embracing new situations.", trait: TRAITS.ADAPTABILITY },
    { id: "q12_lk", text: "I recover quickly from setbacks.", trait: TRAITS.RESILIENCE },
    { id: "q13_lk", text: "I frequently seek out new activities and hobbies in my free time.", trait: TRAITS.OPENNESS_TO_EXPERIENCES },
    { id: "q14_lk", text: "I consistently maintain an organized personal space and schedule.", trait: TRAITS.CONSCIENTIOUSNESS },
    { id: "q15_lk", text: "I actively resolve conflicts in my personal relationships, rather than avoid them.", trait: TRAITS.EMOTIONAL_MATURITY },
    { id: "q16_lk", text: "I regularly try new approaches to improve my daily routines.", trait: TRAITS.INNOVATIVENESS },
    { id: "q17_lk", text: "I actively foresee and prepare for potential challenges.", trait: TRAITS.PROACTIVITY },
    { id: "q18_lk", text: "I eagerly engage in group activities and projects.", trait: TRAITS.COLLABORATION },
    { id: "q19_lk", text: "I always consider multiple perspectives when solving problems.", trait: TRAITS.CRITICAL_THINKING },
    { id: "q20_lk", text: "I enjoy staying current with the latest digital trends and applications.", trait: TRAITS.DIGITAL_LITERACY }
];