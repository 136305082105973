// src/services/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

// Your Firebase configuration (use environment variables)
const firebaseConfig = {
 apiKey: "AIzaSyAPiW-WJm12M5HqKTjZf-m0Mu11fYfoVnw",
  authDomain: "future-readiness-test-63508.firebaseapp.com",
  projectId: "future-readiness-test-63508",
  storageBucket: "future-readiness-test-63508.appspot.com",
  messagingSenderId: "43703670099",
  appId: "1:43703670099:web:497b6407015e1c304dbda6",
  measurementId: "G-0NHJKRQ8BC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const functions = getFunctions(app, 'us-central1');

export { app, db, functions, httpsCallable };