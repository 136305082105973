// src/components/hooks/useStartAssessment.js
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FirebaseContext } from '../contexts/FirebaseContext';
import { useAppGlobalState } from './useGlobalState';
import { handleError } from '../utils/errorHandler';

export const useStartAssessment = () => {
    const { dispatch } = useAppGlobalState();
    const navigate = useNavigate();
    const { db, functions } = useContext(FirebaseContext); // Use if needed
    console.log('DB and Functions:', db, functions);

    const startAssessment = async (event) => {
        event.preventDefault();
        const form = event.target;
        const name = form.name.value.trim();
        const email = form.email.value.trim();

        if (!name || !email) {
            alert('Please fill in all required fields correctly.');
            return;
        }

        try {
            dispatch({ type: 'UPDATE_USER', payload: { name, email } });
            navigate('/intro');
        } catch (error) {
            handleError(error, 'Failed to start assessment.');
        }
    };

    return startAssessment;
};