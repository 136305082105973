import { useGlobalContext } from '../contexts/GlobalStateContext';

// Define the hook as a function first, then call useGlobalContext inside it
export const useAppGlobalState = () => {
    const { state, dispatch } = useGlobalContext(); // ✅ Call inside the hook

    const updateUser = (name, email) => {
        dispatch({ type: 'UPDATE_USER', payload: { name, email } });
    };

    const updateResponses = (responses) => {
        dispatch({ type: 'UPDATE_RESPONSES', payload: responses });
    };

    const setSection = (section) => {
        dispatch({ type: 'SET_SECTION', payload: section });
    };

    const setSummary = (summary) => {
        dispatch({ type: 'SET_SUMMARY', payload: summary });
    };

    const setArchetype = (archetype) => {
        dispatch({ type: 'SET_ARCHETYPE', payload: archetype });
    };

    const setScores = (scores) => {
        dispatch({ type: 'SET_SCORES', payload: scores });
    };

    const setAvgScores = (avgScores) => {
        dispatch({ type: 'SET_AVG_SCORES', payload: avgScores });
    };

    const setArchetypeDistribution = (distribution) => {
        dispatch({ type: 'SET_ARCHETYPE_DISTRIBUTION', payload: distribution });
    };

    return {
        state,
        updateUser,
        updateResponses,
        setSection,
        setSummary,
        setArchetype,
        setScores,
        setAvgScores,
        setArchetypeDistribution
    };
};