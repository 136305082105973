// src/data/archetypeProfiles.js
export const archetypeProfiles = {
    "The Pioneer": {
        "DigitalLiteracy": 1.2,
        "Innovativeness": 1,
        "Adaptability": 1
      },
      "The Architect": {
        "CriticalThinking": 1.2,
        "Conscientiousness": 1,
        "Proactivity": 1
      },
      "The Collaborator": {
        "Collaboration": 1.2,
        "EmotionalMaturity": 1,
        "OpennessToExperiences": 1
      },
      "The Pathfinder": {
        "Resilience": 1.2,
        "Adaptability": 1,
        "Proactivity": 1
      },
      "The Visionary": {
        "OpennessToExperiences": 1.2,
        "Innovativeness": 1,
        "CriticalThinking": 1
      },
      "The Innovator": {
        "Innovativeness": 1.2,
        "DigitalLiteracy": 1,
        "Conscientiousness": 1
      },
      "The Empath": {
        "EmotionalMaturity": 1.2,
        "Resilience": 1,
        "Collaboration": 1
      },
      "The Executor": {
        "Adaptability": 1.2,
        "Resilience": 1,
        "OpennessToExperiences": 1
      },
      "The Unifier": {
        "Proactivity": 1.2,
        "Collaboration": 1,
        "CriticalThinking": 1
      },
      "The Analyst": {
        "Conscientiousness": 1.2,
        "DigitalLiteracy": 1,
        "EmotionalMaturity": 1
      }
    };

