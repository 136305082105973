// src/components/IntroSection.js
import React, { useEffect, useRef } from 'react';
import { useAppGlobalState } from '../../hooks/useGlobalState';
import { useNavigate } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Hero from './Hero';
import { createStars, twinkleStars } from '../../utils/animation';
import styles from '../../styles/IntroSection.module.css'; // Import dedicated CSS module
import sharedStyles from '../../styles/Shared.module.css'; // Import shared styles (e.g., for buttons)

gsap.registerPlugin(ScrollTrigger);

const IntroSection = () => {
    const { state } = useAppGlobalState();
    console.log(state);
    const navigate = useNavigate();

    // Refs for animated elements
    const sectionRef = useRef(null);
    const problemRef = useRef(null);
    const solutionRef = useRef(null);
    const benefitsRef = useRef(null);

    useEffect(() => {
        // Initialize stars and their animations
        createStars();
        twinkleStars();

        // GSAP Context for scoping animations
        let ctx = gsap.context(() => {
            // Fade in the problem section
            gsap.from(problemRef.current, {
                scrollTrigger: {
                    trigger: problemRef.current,
                    start: "top 80%",
                    toggleActions: "play none none reverse",
                },
                opacity: 0,
                y: 50,
                duration: 1,
                ease: "power2.out",
            });

            // Slide in the solution section
            gsap.from(solutionRef.current, {
                scrollTrigger: {
                    trigger: solutionRef.current,
                    start: "top 80%",
                    toggleActions: "play none none reverse",
                },
                opacity: 0,
                x: -100,
                duration: 1,
                ease: "power2.out",
            });

            // Scale up the benefits section
            gsap.from(benefitsRef.current, {
                scrollTrigger: {
                    trigger: benefitsRef.current,
                    start: "top 80%",
                    toggleActions: "play none none reverse",
                },
                opacity: 0,
                scale: 0.8,
                duration: 1,
                ease: "power2.out",
            });
        }, sectionRef); // Scope animations to IntroSection

        // Cleanup function to revert GSAP context and kill ScrollTriggers
        return () => {
            ctx.revert();
        };
    }, []);

    const handleContinue = () => {
        navigate('/start');
    };

    return (
        <section id="intro-section" className={styles.fullScreen} ref={sectionRef}>
            <div className={styles.stars}></div>

            <Hero />

            <article id="problem" className={styles.article} ref={problemRef}>
                <p>Feeling stuck? You're not alone. In a world that's always "on," it's easy to lose sight of your goals.</p>
            </article>

            <article id="solution" className={styles.article} ref={solutionRef}>
                <p>Accelerated delivers the insights you need to thrive. Tailored assessments. Actionable strategies. A clear path to achieving your full potential.</p>
            </article>

            <article id="benefits" className={styles.article} ref={benefitsRef}>
                <h2>What You'll Gain</h2>
                <div className={styles.benefitItem}>
                    <h3>Career</h3>
                    <p>Tired of feeling stagnant? Get promoted, switch fields, or launch your dream business.</p>
                </div>

                <div className={styles.benefitItem}>
                    <h3>Technology</h3>
                    <p>Don't just survive the digital age, master it. Personalized roadmap to essential skills.</p>
                </div>

                <div className={styles.benefitItem}>
                    <h3>Life</h3>
                    <p>More than just a job - find work that fuels you. Discover your motivations and make them work for YOU.</p>
                </div>
            </article>

            {/* Continue Button */}
            <button className={sharedStyles.btn} onClick={handleContinue}>Continue</button>
        </section>
    );
};

export default IntroSection;