// src/components/charts/RadarChart.js
import React from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import styles from '../../styles/styles.module.css'; // Import CSS module

<div className={styles.someClass}>...</div>

Chart.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const RadarChartComponent = ({ data }) => {
    const chartData = {
        labels: Object.keys(data),
        datasets: [
            {
                label: 'Trait Scores',
                data: Object.values(data),
                backgroundColor: 'rgba(34, 202, 236, 0.2)',
                borderColor: 'rgba(34, 202, 236, 1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        scales: {
            r: {
                beginAtZero: true,
                max: 100
            }
        }
    };

    return <Radar data={chartData} options={options} />;
};

export default RadarChartComponent;