// src/services/results.js
import { functions, httpsCallable } from './firebase';
import { gsap } from 'gsap';
import { showConfetti } from '../utils/animation';
import { handleError } from '../utils/errorHandler';

console.log(gsap);

export async function generateSummary(normalizedScores, archetype, setError) {
    try {
        // Use the functions instance directly
        const generateSummaryFunction = httpsCallable(functions, 'generateSummary');
        const result = await generateSummaryFunction({ scores: normalizedScores, archetype: archetype });
        return result.data.summary;
    } catch (error) {
        const errorMessage = handleError(error, 'An error occurred while generating your summary.');
        setError(errorMessage);
        return 'Unable to generate summary at this time.';
    }
}

export async function displayResults(normalizedScores, archetype, setError, updateState) {
    try {
        const summary = await generateSummary(normalizedScores, archetype, setError);
        updateState({ summary: summary });

        // If you need animations with gsap or showConfetti, call them here:
        // gsap.to(...);
        showConfetti();
    } catch (error) {
        const errorMessage = handleError(error, 'An error occurred while displaying your results.');
        setError(errorMessage);
    }
}
