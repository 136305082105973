// src/contexts/GlobalStateContext.js
import React, { createContext, useContext, useReducer, useState } from 'react';

const GlobalStateContext = createContext();

const initialState = {
    userName: null,
    userEmail: null,
    currentSection: null, // 'likert', 'situational', 'binary'
    userResponses: {},
    rawScores: {},
    normalizedScores: {},
    userArchetype: null,
    summary: null,
    avgScores: {},
    archetypeDistribution: {}
};

// Reducer function to manage state updates
function reducer(state, action) {
    switch (action.type) {
        case 'UPDATE_USER':
            return { ...state, userName: action.payload.name, userEmail: action.payload.email };
        case 'UPDATE_RESPONSES':
            return { ...state, userResponses: { ...state.userResponses, ...action.payload } };
        case 'SET_SECTION':
            return { ...state, currentSection: action.payload };
        case 'SET_SUMMARY':
            return { ...state, summary: action.payload };
        case 'SET_ARCHETYPE':
            return { ...state, userArchetype: action.payload };
        case 'SET_SCORES':
            return { ...state, scores: action.payload };
        case 'SET_AVG_SCORES':
            return { ...state, avgScores: action.payload };
        case 'SET_ARCHETYPE_DISTRIBUTION':
            return { ...state, archetypeDistribution: action.payload };
        case 'UPDATE_STATE':
            // Generic action to merge in partial updates
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

export const GlobalStateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [error, setError] = useState(null);

    // Provide both state and dispatch here
    const value = { state, dispatch, error, setError };

    return (
        <GlobalStateContext.Provider value={value}>
            {children}
        </GlobalStateContext.Provider>
    );
};

// Hook to access the full context value
export const useGlobalContext = () => {
    const context = useContext(GlobalStateContext);
    if (context === undefined) {
        throw new Error('useGlobalContext must be used within a GlobalStateProvider');
    }
    return context; // { state, dispatch, error, setError }
};

// Optional convenience hooks if you want them:
export const useGlobalState = () => {
    const { state } = useGlobalContext();
    return state;
};

export const useGlobalDispatch = () => {
    const { dispatch } = useGlobalContext();
    return dispatch;
};