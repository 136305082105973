// src/components/assessment/Results.js
import React, { useEffect, useState } from 'react';
import { useGlobalState } from '../../contexts/GlobalStateContext';
import { generateSummary, displayResults } from '../../services/results';
import { createUserResultsDashboard } from '../../services/dashboard';
import { gsap } from 'gsap';
import { showConfetti } from '../../utils/animation';
import RadarChart from '../charts/RadarChart';
import BarChart from '../charts/BarChart';
import PieChart from '../charts/PieChart';
import styles from '../../styles/styles.module.css'; // Import CSS module

<div className={styles.someClass}>Content</div>

console.log(generateSummary); // To mark generateSummary as used

const Results = () => {
    const { state, updateState, setError } = useGlobalState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchResults = async () => {
            try {
                // Fetch summary and other data if not already done
                if (!state.summary) {
                    await displayResults(state.normalizedScores, state.userArchetype, setError, updateState);
                }

                // GSAP Animations
                gsap.from('#results > *', {
                    opacity: 0,
                    y: 50,
                    duration: 0.5,
                    stagger: 0.2
                });

                gsap.from('#trait-scores p', {
                    width: 0,
                    duration: 1,
                    stagger: 0.1,
                    ease: "power2.out"
                });

                await createUserResultsDashboard('results-details');
                showConfetti();
            } catch (error) {
                // Errors are handled in services/results.js
            } finally {
                setLoading(false);
            }
        };

        fetchResults();
    }, [state.normalizedScores, state.userArchetype, updateState, setError, state.summary]);

    if (loading) {
        return (
            <section id="results" className="full-screen loading">
                <h2>Your Kinetic Index Results</h2>
                <p>Loading your results...</p>
            </section>
        );
    }
    
    return (
        <section id="results" className="full-screen">
            <h2>Your Kinetic Index Results</h2>

            <div id="archetype-container">
                <h3>Your Archetype: {state.userArchetype}</h3>
            </div>

            <div id="results-details">
                <div id="trait-scores">
                    <h3>Your Trait Scores:</h3>
                    {Object.entries(state.normalizedScores).map(([trait, score]) => (
                        <p key={trait}>{trait}: {score.toFixed(2)}</p>
                    ))}
                </div>
                <div id="results-summary">
                    <h3>Summary:</h3>
                    <p dangerouslySetInnerHTML={{ __html: state.summary }}></p>
                </div>

                <div id="charts">
                    <div className="chart-container">
                        <RadarChart data={state.normalizedScores} />
                    </div>
                    <div className="chart-container">
                        <BarChart data={state.rawScores} />
                    </div>
                    <div className="chart-container">
                        <PieChart data={state.rawScores} />
                    </div>
                </div>
            </div>

            <aside id="additional-info">
                <div id="expert-consultation">
                    <p>Want to know more?</p>
                    <p>Book a session to create a personalized plan for targeted success.</p>
                    <button id="book-session" className="btn">Book a Session</button>
                </div>

                <div id="social-sharing">
                    <h3>Share your results:</h3>
                    <button id="share-facebook" className="btn">Facebook</button>
                    <button id="share-twitter" className="btn">Twitter</button>
                    <button id="share-linkedin" className="btn">LinkedIn</button>
                </div>
            </aside>
        </section>
    );

};

const handleShare = (platform) => {
    const shareURL = window.location.href;
    const shareText = encodeURIComponent("Check out my Kinetic Index Results!");

    let url = '';
    switch (platform) {
        case 'facebook':
            url = `https://www.facebook.com/sharer/sharer.php?u=${shareURL}`;
            break;
        case 'twitter':
            url = `https://twitter.com/intent/tweet?text=${shareText}&url=${shareURL}`;
            break;
        case 'linkedin':
            url = `https://www.linkedin.com/shareArticle?mini=true&url=${shareURL}&title=${shareText}`;
            break;
        default:
            return;
    }
    window.open(url, '_blank');
};

console.log(handleShare); // Mark handleShare as used

console.log(gsap.to); // Mark handleShare as used

export default Results;