// src/components/TestSection.js
import React, { useEffect } from 'react';
import { useGlobalState } from '../../contexts/GlobalStateContext';
import { useNavigate } from 'react-router-dom';
import { gsap } from 'gsap';
import Questions from './Questions';
import { likertQuestions } from '../../data/testQuestions/likertQuestions';
import { situationalQuestions } from '../../data/testQuestions/situationalQuestions';
import { binaryQuestions } from '../../data/testQuestions/binaryQuestions';
import { updateScoresInState } from '../../services/scoring';
import styles from '../../styles/styles.module.css'; // Import CSS module

console.log(updateScoresInState);

// Placeholder implementations:
function loadTestSection() {
    // TODO: implement logic
    console.log('loadTestSection called');
  }
  
  function renderQuestions() {
    // TODO: implement logic
    console.log('renderQuestions called');
  }
  
  function updateProgress() {
    // TODO: implement logic
    console.log('updateProgress called');
  }
  
<div className={styles.someClass}>Content</div>

  const totalQuestions = 60; // or some number
  
  function generateSummary() {
    // TODO: implement logic
    console.log('generateSummary called');
  }

const TestSection = () => {
    const { state, updateState, setError } = useGlobalState();
    const navigate = useNavigate();

    useEffect(() => {
        gsap.from('#test-section .content', {
            opacity: 0,
            y: 50,
            duration: 0.8,
            onComplete: () => {
                const sectionQuestions = loadTestSection(state.currentSection);
                renderQuestions(sectionQuestions, state);
                updateProgress(1, totalQuestions); // Define totalQuestions appropriately
                document.getElementById('section-name').textContent = state.currentSection;
            }
        });
    }, [state.currentSection, state]);

    const handleNext = async () => {
        // Validate responses
        const allQuestionsAnswered = Object.keys(state.userResponses).length === 60; // 20 likert + 20 situational + 20 binary
        if (!allQuestionsAnswered) {
            setError('Please answer all questions before proceeding.');
            return;
        }

        try {
            const summaryData = await generateSummary(state.userName, state.userEmail, state.userResponses);
            updateState({
                summary: summaryData.summary,
                archetype: summaryData.archetype,
                normalizedScores: summaryData.scores,
                avgScores: summaryData.avgScores,
                archetypeDistribution: summaryData.archetypeDistribution
            });
            navigate('/loading');
        } catch (error) {
            setError('An error occurred while generating your summary. Please try again later.');
        }
    };

    const getCurrentQuestions = () => {
        switch (state.currentSection) {
            case 'likert':
                return likertQuestions;
            case 'situational':
                return situationalQuestions;
            case 'binary':
                return binaryQuestions;
            default:
                return [];
        }
    };

    return (
        <section id="test-section" className="full-screen">
            <div className="content">
                <h2 id="section-name">{state.currentSection.toUpperCase()} QUESTIONS</h2>
                <div id="progress-container">
                    <div id="progress-bar" style={{ width: `${(Object.keys(state.userResponses).length / (likertQuestions.length + situationalQuestions.length + binaryQuestions.length)) * 100}%` }}></div>
                    <div id="progress-text">
                        Question <span id="current-question">{Object.keys(state.userResponses).length + 1}</span> of <span id="total-questions">{likertQuestions.length + situationalQuestions.length + binaryQuestions.length}</span>
                    </div>
                </div>
                <Questions questions={getCurrentQuestions()} />
                <button id="next-section" className="btn" onClick={handleNext}>Next</button>
            </div>
        </section>
    );
};

export default TestSection;