// src/contexts/FirebaseContext.js
import React, { createContext } from 'react';
import { app, db, functions } from '../services/firebase';

export const FirebaseContext = createContext();

export const FirebaseProvider = ({ children }) => {
    return (
        <FirebaseContext.Provider value={{ app, db, functions }}>
            {children}
        </FirebaseContext.Provider>
    );
};