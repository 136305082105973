// src/components/Hero.js
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import gsap from 'gsap';
import { useNavigate } from 'react-router-dom';
import styles from '../../styles/styles.module.css'; // Import CSS module

const Hero = () => {
    const navigate = useNavigate();

    const handleGetStarted = () => {
        navigate('/intro-section'); // Directly navigate to IntroSection
    };

    const headlineRef = useRef(null);
    const subHeadlineRef = useRef(null);

    useEffect(() => {
        gsap.from(headlineRef.current, { opacity: 0, y: -50, duration: 1, ease: 'bounce.out' });
        gsap.from(subHeadlineRef.current, { opacity: 0, y: 50, duration: 1, delay: 0.5 });
    }, []);

    return (
        <article id="hero" className={`${styles['full-screen']} ${styles['hero-section']}`}>
            <Helmet>
                <title>Forge Your Own Path - Accelerated</title>
                <meta name="description" content="Tailored insights to navigate and thrive in a fast-changing world." />
            </Helmet>
            <h1 id="main-headline">Forge Your Own Path</h1>
            <h2 id="sub-headline">Tailored insights to navigate and thrive in a fast-changing world.</h2>
            <button className="btn" onClick={handleGetStarted}>Get Started</button>
        </article>
    );
};

export default Hero;