// src/data/testQuestions/binaryQuestions.js
import { TRAITS } from '../traitMapping';

export const binaryQuestions = [
    {
        id: "q1_bi",
        text: "When faced with an unexpected change in plans, I typically...",
        options: [
            { key: "0", text: "Take a moment to assess the situation before adjusting." },
            { key: "1", text: "Pivot quickly by creating a new plan." }
        ],
        trait: TRAITS.ADAPTABILITY
    },
    {
        id: "q2_bi",
        text: "After facing a setback, I usually...",
        options: [
            { key: "0", text: "Reflect on the experience to gain insights." },
            { key: "1", text: "Immediately take action to move forward." }
        ],
        trait: TRAITS.RESILIENCE
    },
    {
        id: "q3_bi",
        text: "When it comes to trying new foods, I tend to...",
        options: [
            { key: "0", text: "Prefer familiar dishes with occasional new tastes." },
            { key: "1", text: "Seek out the most unfamiliar items on the menu." }
        ],
        trait: TRAITS.OPENNESS_TO_EXPERIENCES
    },
    {
        id: "q4_bi",
        text: "When preparing for an important task, I typically...",
        options: [
            { key: "0", text: "Focus on key points and adjust details as needed." },
            { key: "1", text: "Create a detailed plan covering all possible aspects." }
        ],
        trait: TRAITS.CONSCIENTIOUSNESS
    },
    {
        id: "q5_bi",
        text: "A tense discussion arises at a social gathering, one that I have strong feelings about, I manage my emotions by...",
        options: [
            { key: "0", text: "Taking a step back to avoid potential conflict." },
            { key: "1", text: "Staying calm and listening to understand different perspectives." }
        ],
        trait: TRAITS.EMOTIONAL_MATURITY
    },
    {
        id: "q6_bi",
        text: "When decorating my space, I prefer...",
        options: [
            { key: "0", text: "A classic style with a personal touch." },
            { key: "1", text: "An unconventional theme that stands out." }
        ],
        trait: TRAITS.INNOVATIVENESS
    },
    {
        id: "q7_bi",
        text: "My approach to goals is...",
        options: [
            { key: "0", text: "Focus on current tasks before setting new objectives." },
            { key: "1", text: "Continuously set new, challenging targets." }
        ],
        trait: TRAITS.PROACTIVITY
    },
    {
        id: "q8_bi",
        text: "In group projects, I usually...",
        options: [
            { key: "0", text: "Take on specific tasks and ensure they're completed well." },
            { key: "1", text: "Share ideas and work with others to reach the goal." }
        ],
        trait: TRAITS.COLLABORATION
    },
    {
        id: "q9_bi",
        text: "When faced with a complex problem at work, I'm more likely to...",
        options: [
            { key: "0", text: "Use methods that have worked in similar situations." },
            { key: "1", text: "Examine the issue from multiple angles before deciding." }
        ],
        trait: TRAITS.CRITICAL_THINKING
    },
    {
        id: "q10_bi",
        text: "For everyday tasks, I tend to...",
        options: [
            { key: "0", text: "Rely on familiar tools and methods." },
            { key: "1", text: "Explore new solutions that might improve efficiency." }
        ],
        trait: TRAITS.DIGITAL_LITERACY
    },
    {
        id: "q11_bi",
        text: "When plans change unexpectedly, I typically...",
        options: [
            { key: "0", text: "Try to maintain as much of the original plan as possible." },
            { key: "1", text: "Quickly create a new plan from scratch." }
        ],
        trait: TRAITS.ADAPTABILITY
    },
    {
        id: "q12_bi",
        text: "After a significant setback, I usually...",
        options: [
            { key: "0", text: "Take time to process before moving forward." },
            { key: "1", text: "Immediately start on the next attempt." }
        ],
        trait: TRAITS.RESILIENCE
    },
    {
        id: "q13_bi",
        text: "When it comes to new experiences, I...",
        options: [
            { key: "0", text: "Prefer familiar situations with occasional new elements." },
            { key: "1", text: "Actively seek out completely new experiences." }
        ],
        trait: TRAITS.OPENNESS_TO_EXPERIENCES
    },
    {
        id: "q14_bi",
        text: "When managing my daily tasks, I typically...",
        options: [
            { key: "0", text: "Have a general plan but remain flexible throughout the day." },
            { key: "1", text: "Create a structured to-do list and prioritize items." }
        ],
        trait: TRAITS.CONSCIENTIOUSNESS
    },
    {
        id: "q15_bi",
        text: "When tensions rise in a group discussion, I tend to...",
        options: [
            { key: "0", text: "Focus on facts to move the conversation forward." },
            { key: "1", text: "Address the underlying concerns of group members." }
        ],
        trait: TRAITS.EMOTIONAL_MATURITY
    },
    {
        id: "q16_bi",
        text: "During brainstorming sessions, I...",
        options: [
            { key: "0", text: "Prefer following a structured approach." },
            { key: "1", text: "Enjoy generating unconventional ideas." }
        ],
        trait: TRAITS.INNOVATIVENESS
    },
    {
        id: "q17_bi",
        text: "When solving a familiar problem, I usually...",
        options: [
            { key: "0", text: "Refine and improve upon previously successful solutions." },
            { key: "1", text: "Try to find a completely new approach." }
        ],
        trait: TRAITS.PROACTIVITY
    },
    {
        id: "q18_bi",
        text: "In team settings, I...",
        options: [
            { key: "0", text: "Work well independently and contribute when needed." },
            { key: "1", text: "Thrive in group discussions and idea sharing." }
        ],
        trait: TRAITS.COLLABORATION
    },
    {
        id: "q19_bi",
        text: "When faced with a complex problem, I...",
        options: [
            { key: "0", text: "Focus on finding a quick, practical solution." },
            { key: "1", text: "Take time to analyze all aspects before deciding." }
        ],
        trait: TRAITS.CRITICAL_THINKING
    },
    {
        id: "q20_bi",
        text: "On a trip with limited internet access, I...",
        options: [
            { key: "0", text: "Rely on familiar methods and local assistance." },
            { key: "1", text: "Prepare alternative tools and resources in advance." }
        ],
        trait: TRAITS.DIGITAL_LITERACY
    }
];